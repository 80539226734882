import React from 'react'
import HomeHeader from "../components/homepage-header"
import AniLink from "gatsby-plugin-transition-link/AniLink/index"
import Footer from "../components/footer"

const Home = (props) => {
  const pages = props.pageContext.pages;

  let page = {};
  if(!!pages) {
    pages.forEach((item) => {
      if (item.isHomepage) {
        page = item;
      }
    })
  }

  return (
    <main style={{backgroundColor: page.backgroundColor}}>
      <HomeHeader background={page.backgroundColor} {...props} />

      <div className="container-fluid">
          <video className={"home-video"} playsInline={true} src={page.video} autoPlay={true} loop={true} muted={true} />
      </div>

      <Footer {...props} />
    </main>
  )
}

export default Home